import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 查询生活圈的结算帐单
 * @param {Object} data
 */
export function listLifeBill (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/listLifeBill',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 查询生活圈结算帐单的计费项明细表
 * @param {Object} data
 */
export function listLifeBillDetail (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/listLifeBillDetail',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取生活圈结算单状态
 * @param {Object} data
 */
export function listLifeBillStatus (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/listLifeBillStatus',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取生活圈收支类型
 * @param {Object} data
 */
export function listLifeIncomeType (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/listLifeIncomeType',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取生活圈结算公司列表
 * @param {Object} data
 */
export function listLifeSettlementCompany (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/listLifeSettlementCompany',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取生活圈费用类型列表
 * @param {Object} data
 */
export function listLifeFeeItemType (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/listLifeFeeItemType',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 结算单确认结算
 * @param {Object} data
 */
export function confirmBill (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/confirmBill',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 获取动态显示的字段
 * @param {Object} data
 */
export function getModuleList (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/getModuleList',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取账单详情基本信息
 * @param {Object} data
 */
export function getBillDetail (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/getBillDetail',
    method: 'post',
    data: qs.stringify(data)
  })
}
