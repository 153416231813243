<template>
  <div>
    <Row :gutter="8" class="m-b-10">

      <i-col span="4">
        <DatePicker placeholder="入账开始结束时间" size="small" v-model="dateRage" type="daterange" split-panels
          style="width:100%" :editable="false" clearable @on-change="onChangeDate"></DatePicker>
      </i-col>

      <i-col span="4">
        <Select v-model="query.incomeType" clearable size="small" placeholder="收支类型">
          <Option v-for="item in lifeIncomeTypeArray" :key="'incomeType_' + item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>

      <i-col span="4">
        <Select v-model="query.feeItemType" clearable filterable size="small" placeholder="服务类型">
          <Option v-for="item in lifeFeeItemTypeArray" :key="'feeItem_' + item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <Select v-model="query.settlementCompanyId" clearable filterable size="small" placeholder="服务主体">
          <Option v-for="item in companyArray" :key="'selCom_' + item.settlementCompanyId" :value="item.settlementCompanyId">{{ item.settlementCompanyName }}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <i-input v-model="query.keyWord" size="small" placeholder="关键字（服务主体、业务单号等）"></i-input>
      </i-col>
      <i-col span="4">
        <i-button size="small" class="m-r-5" type="primary" icon="ios-search" @click="handleSearch">搜索</i-button>
        <i-button size="small" class="m-r-5" type="success" icon="ios-search" @click="onExport">导出Excel</i-button>
      </i-col>
    </Row>
    <!-- <Row :gutter="8" class="m-b-10">
      <i-col span="12" class="text-center">
        <Card>
          <div class="p-b-10 p-t-10">
            <h2>核销总数量</h2>
          </div>
          <div class="text-orange">
            <h1>{{ formartNumber(quantity) }}</h1>
          </div>
        </Card>

      </i-col>
      <i-col span="12" class="text-center">
        <Card>
          <div class="p-b-10 p-t-10">
            <h2>核销总金额</h2>
          </div>
          <div class="money">
            <h1>{{ formartMoney(amount) }}</h1>
          </div>
        </Card>

      </i-col>
    </Row> -->
    <Table size="small" stripe :data="list" :columns="tableColumns" :loading="tableLoading"></Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber"
        @on-change="handlePageChange"></Page>
    </div>
  </div>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'
import { toMoney, toNumber } from '@/utils/wnumb_own'

import { listLifeBillDetail, listLifeSettlementCompany, listLifeIncomeType, listLifeFeeItemType } from '@/api/statement/lifebill'
export default {
  props: {
  },
  data () {
    return {
      companyArray: [],
      lifeIncomeTypeArray: [],
      lifeFeeItemTypeArray: [],
      salerArray: [],
      dateRage: [],
      amount: 0, // 核销总金额
      quantity: 0, // 核销总数量
      query: {
        pageNumber: 1,
        pageSize: 15,
        keyWord: ''
      },
      list: [],
      total: 0,
      tableLoading: false,
      tableColumns: [{ title: '类型', key: 'feeItemTypeName', align: 'center' },
        { title: '服务主体', key: 'settlementCompanyName', align: 'center' },
        { title: '入账时间', key: 'chargeTime', align: 'center' },
        { title: '业务单号', key: 'goodNo', align: 'center' },
        { title: '名称(商品、媒体)', key: 'goodName', align: 'center' },
        { title: '收支类型', key: 'incomeTypeName', align: 'center' },
        {
          title: '单价(元)',
          key: 'price',
          align: 'center',
          render (h, { row }) {
            return h('span', {
              style: { color: '#44bd32', 'font-weight': 'bold' }
            }, toMoney(row.price))
          }
        },
        {
          title: '数量',
          key: 'quantity',
          align: 'center',
          render (h, { row }) {
            return h('span', {
            }, toNumber(row.quantity))
          }
        },
        {
          title: '完成金额(元)',
          key: 'verifyAmount',
          align: 'center',
          render (h, { row }) {
            return h('span', {
              style: { color: '#44bd32', 'font-weight': 'bold' }
            }, toMoney(row.verifyAmount))
          }
        },
        { title: '计价方式', key: 'chargeModeDesc', align: 'center' },
        {
          title: '金额(元)',
          key: 'actualAmount',
          align: 'center',
          render (h, { row }) {
            return h('span', {
              style: { color: '#44bd32', 'font-weight': 'bold' }
            }, toMoney(row.actualAmount))
          }
        }]
    }
  },
  created () {
    // this.query.publisherId = this.$store.getters.token.userInfo.publisherId=
    this.getCompanyData()
    this.getLifeFeeItemTypeData()
    this.getLifeIncomeTypeData()
    this.getTableData()
  },
  methods: {
    getTableData () {
      this.tableLoading = true
      listLifeBillDetail(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    onChangeDate (date) {
      this.query.startDate = date[0]
      this.query.endDate = date[1]
    },
    /**
     * 获取服务主体
     */
    getCompanyData () {
      this.companyArray = []
      listLifeSettlementCompany({ }).then(res => {
        if (res && !res.errcode) {
          this.companyArray = res
        }
      })
    },
    /**
     * 获取收支类型
     */
    getLifeIncomeTypeData () {
      this.lifeIncomeTypeArray = []
      listLifeIncomeType({ }).then(res => {
        if (res && !res.errcode) {
          this.lifeIncomeTypeArray = res
        }
      })
    },
    /**
     * 获取费用类型
     */
    getLifeFeeItemTypeData () {
      this.lifeFeeItemTypeArray = []
      listLifeFeeItemType({ }).then(res => {
        if (res && !res.errcode) {
          this.lifeFeeItemTypeArray = res
        }
      })
    },

    handleSearch () {
      this.query.pageNumber = 1
      this.getTableData()
    },
    handlePageChange (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },

    formartMoney (value) {
      return toMoney(value)
    },
    formartNumber (value) {
      return toNumber(value)
    },
    onExport () {
      const postData = {
        publisherId: this.query.publisherId,
        companyId: this.query.companyId,
        userId: this.query.userId,
        verifyStartDate: this.query.verifyStartDate,
        verifyEndDate: this.query.verifyEndDate,
        keyWords: this.query.keyWords
      }
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-life/v1/verifyBill/exportVerifiedTicketBill', postData, '电子卡券核销清单')
    }
  }
}
</script>
